.policy-warrning {
  position: fixed;
  bottom: 0;
  right: 8%;
  z-index:9999999999;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px 50px 20px 15px;
  color: $dark-gray;
  font-size: 13px;
  box-sizing: border-box;
  width: 400px;
  pointer-events: all;
  float: right;
  font-weight: 300;

  @media (max-width: 800px) {
    &{
      width: 100%;
      margin: 0;
      left: 0;
    }
  }

  &__close-ico {
    position: absolute;
    width: 17px;
    height: 17px;
    font-size: 17px;
    cursor: pointer;
    top: 15px;
    right: 15px;
    background-image: url('/images/icons/close.svg');
    background-size: 17px;
    background-repeat: no-repeat;
  }

  &__read-more {
    font-size: 12px;
    font-weight: 300;
    color: #3c3c3c;
    margin: 10px 0 0 0px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-decoration: underline;
    padding-right: 30px;
    background-position: 100% center;
  }

  &__long-text {
    display: none;
    a {
      text-decoration: underline;
      color: #4e4e4e;
    }
  }
}
.policy-title {
  margin: 20px 0;
  font-size: 20px;
}