.content-slider {
  margin-bottom: 50px;
  &__list {
    position: relative;
    overflow: hidden;
    &.show-all .content-slider__item {
      display: block !important;
    }
    min-height: 175px !important;
    max-height: 175px;
    @include respond-to(sm) {
      min-height: 428px !important;
      max-height: 428px;
    }
    @include respond-to(md) {
      min-height: 560px !important;
      max-height: 560px;
    }
    @include respond-to(lg) {
      min-height: 600px !important;
      max-height: 600px;
    }

  }
  &__item {
    margin: 0 auto;
    width: 80%;
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    .content-slider__item-content {
      opacity: 0;
    }
    &.active {
      .content-slider__item-content {
        opacity: 1;
      }
      .content-slider__picture {

        img {
          @include respond-to(sm) {
            padding: 0 0 50px 65px;
            background-image: url('/images/borders/border_2.png');
            background-repeat: no-repeat;
            background-position: left top;
            background-size: contain;
            transition: padding .3s;
          }
          @include respond-to(md) {
            padding: 0 0 70px 80px;
          }
          @include respond-to(lg) {
            padding: 0 0 100px 130px;
          }
        }
      }
    }
    &.next,
    &.prev {
      .content-slider__picture img {
        cursor: pointer;
      }
    }
    &.animated {
      transition: all 0.5s ease-in-out;
      .content-slider__item-content {
        transition: all 0.5s ease-in-out;
      }
    }

    &:first-child {
      display: block;
    }

  }

  &__picture {
    img {
      padding: 0 0 70px 0;
      margin: 0 auto;
      max-height: 235px;
      @include respond-to(sm) {
        max-height: 529px;
        padding: 0 0 100px 0;
      }
      @include respond-to(md) {
        max-height: 600px;
      }
    }
  }
}
