@charset "UTF-8";
@font-face {
  font-family: 'rouble';
  src: url("/css/fonts/rouble-webfont.eot");
  src: url("/css/fonts/rouble-webfont.eot?#iefix") format("embedded-opentype"), url("/css/fonts/rouble-webfont.woff") format("woff"), url("/css/fonts/rouble-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

img {
  max-width: 100%; }

h1, h2, h3, .h1, .h2, .h3 {
  margin-top: 0;
  margin-bottom: 0.5em;
  word-break: normal; }

h1, .h1 {
  font-size: 30px;
  font-weight: normal;
  color: #34373b;
  font-family: "Geometric", sans-serif;
  line-height: 1.2;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase; }
  @media (min-width: 576px) {
    h1, .h1 {
      font-size: 42px;
      margin-bottom: 40px; } }
  @media (min-width: 992px) {
    h1, .h1 {
      margin-bottom: 70px; } }

h2, .h2 {
  font-size: 40px;
  font-weight: normal;
  color: #34373b;
  font-family: "Geometric", sans-serif;
  line-height: 35px;
  text-transform: uppercase; }

h3, .h3 {
  font-size: 24px;
  font-weight: bold;
  color: #34373b;
  font-family: "Geometric", sans-serif;
  line-height: 30px; }

a {
  color: #34373b;
  text-decoration: none;
  border: 0px solid black; }
  a:visited {
    color: #000; }
  a:hover {
    color: #000;
    text-decoration: underline; }
  a:focus {
    color: #000;
    outline: none; }
  a:active {
    color: #000;
    outline: none; }

p {
  font-size: 15px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 1.4;
  color: #34373b; }

ul {
  list-style: none;
  padding-left: 0em; }
  ul li {
    font-size: 14px;
    line-height: 1.79;
    letter-spacing: normal;
    color: #34373b; }
    ul li:before {
      content: "—";
      color: #34373b;
      padding-right: 10px;
      display: inline-block; }

ol {
  list-style: none;
  counter-reset: item;
  padding-left: 0em; }
  ol li {
    font-size: 14px;
    position: relative;
    line-height: 2.07; }
    ol li:before {
      content: counter(item) ". ";
      counter-increment: item;
      font-weight: bold;
      line-height: 1.71;
      color: #34373b; }

.main-content {
  position: relative;
  background: rgba(255, 255, 255, 0.7);
  flex-grow: 1;
  padding-bottom: 100px; }

.group-item input[type="radio"], .group-item input[type="checkbox"] {
  width: 100%; }
  .group-item input[type="radio"] + label, .group-item input[type="checkbox"] + label {
    cursor: pointer;
    min-height: 25px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 300; }
    .group-item input[type="radio"] + label.doubleText, .group-item input[type="checkbox"] + label.doubleText {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
    .group-item input[type="radio"] + label .mainText, .group-item input[type="checkbox"] + label .mainText {
      margin-bottom: 2px; }
    .group-item input[type="radio"] + label .subText, .group-item input[type="checkbox"] + label .subText {
      font-weight: 300; }
    .group-item input[type="radio"] + label a, .group-item input[type="checkbox"] + label a {
      font-size: 13px; }
  .group-item input[type="radio"]:not(checked), .group-item input[type="checkbox"]:not(checked) {
    position: absolute !important;
    opacity: 0; }
  .group-item input[type="radio"]:not(checked) + label, .group-item input[type="checkbox"]:not(checked) + label {
    position: relative;
    padding: 0 0 0 37px; }
  .group-item input[type="radio"]:not(checked) + label:before, .group-item input[type="checkbox"]:not(checked) + label:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 21px;
    height: 21px;
    border: 1px solid #34373b;
    margin-top: 0px; }
  .group-item input[type="radio"]:not(checked) + label:after, .group-item input[type="checkbox"]:not(checked) + label:after {
    content: '';
    position: absolute;
    top: -1px;
    left: 3px;
    width: 23px;
    height: 23px;
    transition: all .2s;
    display: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/images/icons/checkboz-arrow.svg"); }
  .group-item input[type="radio"]:checked + label:after, .group-item input[type="checkbox"]:checked + label:after {
    display: block; }

.group-item textarea {
  border: solid 1px #34373b;
  font-size: 12px;
  font-weight: 300;
  color: #34373b;
  padding: 10px 15px;
  resize: none;
  width: 100%;
  max-width: 100%;
  height: 150px;
  background-color: transparent;
  outline: none;
  border-radius: 0;
  font-family: "Geometric", sans-serif; }
  .group-item textarea:hover {
    border-color: #ba1a76; }
  .group-item textarea:active, .group-item textarea:focus {
    color: #34373b;
    box-shadow: none;
    border-color: #ba1a76;
    outline: none; }

.group-item input[type="text"], .group-item input[type="password"], .group-item input[type="email"], .group-item input[type="phone"] {
  border: solid 1px #34373b;
  background: transparent;
  font-size: 16px;
  line-height: 51px;
  height: 51px;
  font-weight: 300;
  color: #34373b;
  padding: 0 15px;
  width: 100%;
  border-radius: 0;
  outline: none;
  font-family: "Lato", sans-serif; }
  .group-item input[type="text"]:hover, .group-item input[type="password"]:hover, .group-item input[type="email"]:hover, .group-item input[type="phone"]:hover {
    border-color: #ba1a76; }
  .group-item input[type="text"]:active, .group-item input[type="text"]:focus, .group-item input[type="password"]:active, .group-item input[type="password"]:focus, .group-item input[type="email"]:active, .group-item input[type="email"]:focus, .group-item input[type="phone"]:active, .group-item input[type="phone"]:focus {
    color: #34373b;
    box-shadow: none;
    border-color: #ba1a76;
    outline: none;
    border-radius: 0; }

.group-item input[type="password"] {
  font-weight: 300; }
  .group-item input[type="password"]:active, .group-item input[type="password"]:focus {
    font-weight: 300; }

.group-item label .req {
  color: #34373b;
  font-size: 15px; }

.group-item.failed-input input {
  border-color: #ba1a76; }

.group-item .failed-message {
  color: #ba1a76;
  font-size: 11px;
  font-family: "Lato", sans-serif;
  padding-top: 7px;
  display: block; }

.group-item {
  margin-bottom: 20px;
  position: relative; }

/*
.selectpicker {
    .bootstrap-select {
        width: 300px;
        max-width: 100%;
    }
    &.small {
        .bootstrap-select {
            width: 220px!important;;
        }
    }
    &.medium {
        .bootstrap-select {
            width: 500px!important;;
        }
    }
    &.long {
        .bootstrap-select {
            width: 700px!important;
        }
    }
    .defaultSelect {
        background: white;
        text-transform: uppercase;
        border-radius: 0px;
        border: solid 1px $black;
        //background: transparent;

        font-size: 12px;
        line-height: 35px;
        font-weight: 300;
        color: $dark-gray;
        padding: 0 15px;
        &:focus {
            outline: none !important;
        }
        &.dropdown-toggle .caret {
            //background: url("/images/base_icon/bottom.svg") no-repeat;
            border: 0px;
            width: 23px;
            height: 19px;
            margin-top: 0;
            top: 10px;
        }

        &:hover {
            box-shadow: $defaultShadow;
        }
        &:active, &:focus, &[aria-expanded="true"] {
            box-shadow: $defaultShadow;
            border: solid 1px $black;
            outline: solid 1px $black;
            &.dropdown-toggle .caret {
                //background: url("/images/base_icon/up-blue.svg") no-repeat;
            }
        }
    }
    ul {
        li {
            &:before {
                display: none;
            }
            &.disabled{
                text-align: center;
                background: #ccc;
                .text{
                    color: $white;
                }
            }
        }
    }
    .dropdown-menu {
        padding: 0;
        margin: 0;
        box-shadow: 0px 0px 0px 0px black;
        box-shadow: 0 5px 7px 0 rgba(66, 57, 97, 0.11);
        border: solid 1px $black;
        outline: solid 1px $black;
        border-top: 0px;

        & > li > a {
            text-transform: uppercase;
            line-height: 35px;
            font-weight: 300;
            color: $black;
            padding: 0 15px;
            border-bottom: 1px solid $black;
            &:focus, &:hover, &:visited {
                font-style: normal;
                font-weight: 400;
                color: $black;
                background: none;
                outline: none;
            }
        }
    }
    .custom-select{
        border: 1px solid $dark-gray;
        border-radius: 0px;
    }
}
//стили для загрузки файла
.file-load {
    &__field {
        position: relative;
        overflow: hidden;
        border-bottom: 1px dashed $black;
        cursor: pointer;
        font-size: 12px;

        &:hover {
            border-bottom: 1px solid $black;
        }

        input[type=file] {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            font-size: 1em;
            text-align: right;
            filter: alpha(opacity=0);
            opacity: 0;
            outline: none;
            background: white;
            cursor: inherit;
            display: block;
        }
    }
    &__add-file {
        font-weight: bold;
        margin: 5px 0px;
    }
    &__rem-file {
        width: 1em;
        height: 1em;
        display: block;
        float: left;
        margin-right: 10px;
        cursor: pointer;
    }
}
*/
.default-btn {
  display: inline-block;
  text-align: center;
  font-size: 20px;
  font-family: "Geometric", sans-serif;
  line-height: 24px;
  color: #353035;
  background-color: #FFF;
  padding: 12px 30px 9px;
  margin: 20px 0;
  cursor: pointer;
  border: 3px solid #353035;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  text-transform: uppercase; }
  .default-btn:hover {
    border-color: #ba1a76;
    color: #ba1a76;
    text-decoration: none; }
  .default-btn:active {
    outline: none;
    box-shadow: none;
    border-color: #ba1a76;
    color: #ba1a76; }
  .default-btn:focus {
    outline: none;
    box-shadow: none;
    border-color: #ba1a76;
    color: #ba1a76; }
  .default-btn:visited {
    outline: none;
    box-shadow: none;
    border-color: #353035;
    color: #353035; }
  .default-btn:disabled {
    outline: none;
    box-shadow: none;
    border-color: #eaebeb;
    color: #eaebeb; }
  .default-btn__wrapper {
    display: block;
    text-align: center; }
    .default-btn__wrapper--right {
      text-align: right; }

blockquote {
  font-style: italic;
  margin: 0 0 0;
  font-size: 15px;
  font-style: italic;
  color: #34373b;
  vertical-align: middle;
  font-weight: 300;
  padding-left: 45px;
  position: relative; }
  blockquote:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("/images/icons/quote.svg"); }

hr {
  width: 100%;
  height: 1px;
  background: #000;
  border: 0; }

html {
  height: 100%; }

body {
  color: #34373b;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  overflow-x: hidden;
  line-height: 1.4;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column; }
  body.noscroll {
    overflow: hidden; }

.words-title {
  margin: 100px auto;
  display: block; }
  @media (min-width: 768px) {
    .words-title {
      margin: 200px auto; } }

.page-header__qoute {
  padding: 30px 0;
  text-align: left; }

.page-header__logo {
  text-align: center;
  padding: 10px 0 30px; }
  @media (min-width: 576px) {
    .page-header__logo {
      padding: 80px 0 30px; } }
  .page-header__logo img {
    max-width: 290px; }

.page-header__social {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }

.page-header__social-icon {
  height: 43px;
  width: 43px;
  display: block;
  margin: 7px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }
  .page-header__social-icon--place {
    background-image: url("/images/icons/location.svg");
    opacity: .2;
    cursor: default; }
  .page-header__social-icon--phone {
    background-image: url("/images/icons/phone.svg");
    width: 60px;
    opacity: .2;
    cursor: default; }
  .page-header__social-icon--mail {
    background-image: url("/images/icons/mail.svg"); }
  .page-header__social-icon--inst {
    background-image: url("/images/icons/inst.svg"); }
  .page-header__social-icon--fb {
    background-image: url("/images/icons/fb.svg"); }

.page-footer {
  padding: 10px 0; }
  .page-footer__policy {
    font-size: 12px;
    font-weight: 300; }
  .page-footer__sitecreator {
    font-size: 12px;
    font-weight: 300; }
  .page-footer__up {
    width: 41px;
    height: 41px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("/images/icons/to-up.svg");
    cursor: pointer;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
    opacity: .6; }
    .page-footer__up:hover {
      opacity: 1; }

.letters__item {
  padding: 15px 15px; }
  @media (min-width: 576px) {
    .letters__item {
      padding: 15px 0; } }
  @media (min-width: 768px) {
    .letters__item {
      padding: 25px 10px; } }
  @media (min-width: 992px) {
    .letters__item {
      padding: 25px 10px; } }
  @media (min-width: 1200px) {
    .letters__item {
      padding: 35px 20px; } }

.flowers__footer-img {
  margin: 50px auto 100px;
  position: relative;
  z-index: 10; }
  @media (min-width: 768px) {
    .flowers__footer-img {
      margin: -150px auto 200px; } }
  @media (min-width: 992px) {
    .flowers__footer-img {
      margin: -200px auto 200px; } }

.flowers__content {
  background-image: none;
  padding: 30px 0; }
  @media (min-width: 768px) {
    .flowers__content {
      padding: 30px 140px 30px 0; } }
  @media (min-width: 992px) {
    .flowers__content {
      padding: 50px 130px 130px; } }
  @media (min-width: 1200px) {
    .flowers__content {
      padding: 50px 130px 130px;
      background-image: url("/images/borders/border_1.png");
      background-repeat: no-repeat;
      background-position: left bottom;
      -webkit-background-size: contain;
      background-size: contain; } }

.flowers__title {
  font-size: 40px;
  font-weight: normal;
  font-family: "Geometric", sans-serif;
  line-height: 1.06;
  color: #34373b;
  text-transform: uppercase;
  padding-top: 40px; }
  @media (min-width: 576px) {
    .flowers__title {
      font-size: 50px; } }
  @media (min-width: 768px) {
    .flowers__title {
      font-size: 60px; } }
  @media (min-width: 992px) {
    .flowers__title {
      padding-top: 50px;
      font-size: 70px; } }

.presentation {
  margin-bottom: 100px; }
  @media (min-width: 768px) {
    .presentation {
      margin-bottom: 150px; } }
  .presentation__title {
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 30px;
    font-family: "Geometric", sans-serif;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .presentation__title {
        font-size: 30px;
        font-weight: normal; } }
    @media (min-width: 992px) {
      .presentation__title {
        font-size: 38px;
        font-weight: normal; } }
  .presentation__text {
    margin-bottom: 30px; }
  @media (min-width: 1200px) {
    .presentation__img-wrapper {
      background-repeat: no-repeat;
      background-position: left bottom;
      background-image: url("/images/borders/border_3.png");
      padding: 0 0 30px 120px; } }
  .presentation__img {
    margin-bottom: 20px; }
  .presentation__img-text {
    font-weight: normal;
    font-size: 15px;
    color: #34373b;
    font-family: "Geometric", sans-serif; }
  .presentation .default-btn {
    margin: 20px 0; }

.content-slider {
  margin-bottom: 50px; }
  .content-slider__list {
    position: relative;
    overflow: hidden;
    min-height: 175px !important;
    max-height: 175px; }
    .content-slider__list.show-all .content-slider__item {
      display: block !important; }
    @media (min-width: 576px) {
      .content-slider__list {
        min-height: 428px !important;
        max-height: 428px; } }
    @media (min-width: 768px) {
      .content-slider__list {
        min-height: 560px !important;
        max-height: 560px; } }
    @media (min-width: 992px) {
      .content-slider__list {
        min-height: 600px !important;
        max-height: 600px; } }
  .content-slider__item {
    margin: 0 auto;
    width: 80%;
    display: block;
    position: absolute;
    top: 0;
    left: -100%; }
    .content-slider__item .content-slider__item-content {
      opacity: 0; }
    .content-slider__item.active .content-slider__item-content {
      opacity: 1; }
    @media (min-width: 576px) {
      .content-slider__item.active .content-slider__picture img {
        padding: 0 0 50px 65px;
        background-image: url("/images/borders/border_2.png");
        background-repeat: no-repeat;
        background-position: left top;
        background-size: contain;
        transition: padding .3s; } }
    @media (min-width: 768px) {
      .content-slider__item.active .content-slider__picture img {
        padding: 0 0 70px 80px; } }
    @media (min-width: 992px) {
      .content-slider__item.active .content-slider__picture img {
        padding: 0 0 100px 130px; } }
    .content-slider__item.next .content-slider__picture img, .content-slider__item.prev .content-slider__picture img {
      cursor: pointer; }
    .content-slider__item.animated {
      transition: all 0.5s ease-in-out; }
      .content-slider__item.animated .content-slider__item-content {
        transition: all 0.5s ease-in-out; }
    .content-slider__item:first-child {
      display: block; }
  .content-slider__picture img {
    padding: 0 0 70px 0;
    margin: 0 auto;
    max-height: 235px; }
    @media (min-width: 576px) {
      .content-slider__picture img {
        max-height: 529px;
        padding: 0 0 100px 0; } }
    @media (min-width: 768px) {
      .content-slider__picture img {
        max-height: 600px; } }

.order-form form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start; }
  @media (min-width: 768px) {
    .order-form form {
      justify-content: space-between;
      width: 80%;
      margin: 0 auto; } }

.order-form__message {
  width: 80%;
  min-width: 80%;
  margin: 0 auto 20px; }

.order-form__submit {
  width: 100%;
  min-width: 100%;
  order: 4; }
  @media (min-width: 768px) {
    .order-form__submit {
      width: 15%;
      min-width: 15%;
      order: 3; } }
  .order-form__submit .default-btn {
    margin: 0 0 20px 0;
    padding: 12px 30px 9px; }

.order-form .group-item {
  width: 100%;
  min-width: 100%; }
  @media (min-width: 768px) {
    .order-form .group-item {
      width: 40%;
      min-width: 40%;
      padding-right: 40px; } }
  .order-form .group-item input {
    color: #ba1a76; }
    .order-form .group-item input ::-webkit-input-placeholder {
      font-size: 15px;
      color: #34373b;
      font-weight: 300;
      font-family: "Geometric", sans-serif; }
    .order-form .group-item input :-moz-placeholder {
      font-size: 15px;
      color: #34373b;
      font-weight: 300;
      font-family: "Geometric", sans-serif; }
    .order-form .group-item input ::-moz-placeholder {
      font-size: 15px;
      color: #34373b;
      font-weight: 300;
      font-family: "Geometric", sans-serif; }
    .order-form .group-item input :-ms-input-placeholder {
      font-size: 15px;
      color: #34373b;
      font-weight: 300;
      font-family: "Geometric", sans-serif; }
    .order-form .group-item input:focus ::-webkit-input-placeholder {
      color: #FFF !important; }
    .order-form .group-item input:focus :-moz-placeholder {
      color: #FFF !important; }
    .order-form .group-item input:focus ::-moz-placeholder {
      color: #FFF !important; }
    .order-form .group-item input:focus :-ms-input-placeholder {
      color: #FFF !important; }

.order-form .policy-group-item {
  min-width: 100%;
  order: 3; }
  @media (min-width: 768px) {
    .order-form .policy-group-item {
      width: 100%;
      min-width: 100%;
      order: 4; } }

.policy-warrning {
  position: fixed;
  bottom: 0;
  right: 8%;
  z-index: 9999999999;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px 50px 20px 15px;
  color: #34373b;
  font-size: 13px;
  box-sizing: border-box;
  width: 400px;
  pointer-events: all;
  float: right;
  font-weight: 300; }
  @media (max-width: 800px) {
    .policy-warrning {
      width: 100%;
      margin: 0;
      left: 0; } }
  .policy-warrning__close-ico {
    position: absolute;
    width: 17px;
    height: 17px;
    font-size: 17px;
    cursor: pointer;
    top: 15px;
    right: 15px;
    background-image: url("/images/icons/close.svg");
    background-size: 17px;
    background-repeat: no-repeat; }
  .policy-warrning__read-more {
    font-size: 12px;
    font-weight: 300;
    color: #3c3c3c;
    margin: 10px 0 0 0px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-decoration: underline;
    padding-right: 30px;
    background-position: 100% center; }
  .policy-warrning__long-text {
    display: none; }
    .policy-warrning__long-text a {
      text-decoration: underline;
      color: #4e4e4e; }

.policy-title {
  margin: 20px 0;
  font-size: 20px; }

.text-block {
  background-image: none;
  padding: 30px 0;
  font-size: 15px;
  font-weight: 300; }
  @media (min-width: 768px) {
    .text-block {
      padding: 30px 140px 30px 0; } }
  @media (min-width: 992px) {
    .text-block {
      padding: 50px 130px 130px; } }
  @media (min-width: 1200px) {
    .text-block {
      padding: 50px 130px 130px;
      background-image: url("/images/borders/border_1.png");
      background-repeat: no-repeat;
      background-position: left bottom;
      -webkit-background-size: contain;
      background-size: contain; } }
  .text-block__title {
    font-size: 35px;
    font-weight: normal;
    font-family: "Geometric", sans-serif;
    color: #34373b;
    text-transform: uppercase;
    margin-bottom: 10px; }
  .text-block__subtitle {
    font-size: 22px;
    font-weight: 300;
    font-style: italic;
    color: #34373b;
    font-family: "Lato", sans-serif;
    margin-bottom: 20px; }

.advantages__title {
  margin-bottom: 100px;
  font-size: 40px;
  font-weight: normal;
  font-family: "Geometric", sans-serif;
  color: #34373b;
  text-transform: uppercase; }

.advantages__item {
  background-image: url("/images/landing/borders/border_2.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  -webkit-background-size: contain;
  background-size: contain; }

.advantages__item-img {
  margin: 20px 40px; }

.advantages__item-title {
  font-size: 22px;
  font-weight: 300;
  color: #34373b;
  margin-bottom: 15px; }

.advantages__item-text {
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 50px; }

.team__text-block {
  padding-top: 20px; }

.team__img-block img {
  padding: 0 0 60px 50px;
  background-image: url("/images/landing/borders/border_3.png");
  background-repeat: no-repeat;
  background-position: left top; }

.team__title {
  font-size: 42px;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 15px; }

.team__text {
  font-size: 15px;
  font-weight: 300; }

.services__title {
  font-size: 35px;
  font-weight: normal;
  font-family: "Geometric", sans-serif;
  text-transform: uppercase;
  margin-bottom: 10px; }

.services__subtitle {
  font-size: 22px;
  font-weight: 300;
  font-style: italic;
  font-family: "Lato", sans-serif;
  margin-bottom: 100px; }
