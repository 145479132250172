.page-header {
  &__qoute {
    padding: 30px 0;
    text-align: left;
  }
  &__logo {
    text-align: center;
    padding: 10px 0 30px;
    @include respond-to(sm) {
      padding: 80px 0 30px;
    }
    img {
      max-width: 290px;
    }
  }
  &__social {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__social-icon {
    height: 43px;
    width: 43px;
    display: block;
    margin: 7px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &--place {
      background-image: url('/images/icons/location.svg');
      opacity: .2;
      cursor: default;
    }
    &--phone {
      background-image: url('/images/icons/phone.svg');
      width: 60px;
      opacity: .2;
      cursor: default;
    }
    &--mail {
      background-image: url('/images/icons/mail.svg');
    }
    &--inst {
      background-image: url('/images/icons/inst.svg');
    }
    &--fb {
      background-image: url('/images/icons/fb.svg');
    }
  }
}