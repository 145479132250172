.page-footer {
  padding: 10px 0;
  &__policy {
    font-size: 12px;
    font-weight: 300;
  }
  &__sitecreator {
    font-size: 12px;
    font-weight: 300;
  }
  &__up {
    width: 41px;
    height: 41px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('/images/icons/to-up.svg');
    cursor: pointer;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
    opacity: .6;
    &:hover {
      opacity: 1;
    }
  }
}