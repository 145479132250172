.letters {
  &__title-img {

  }
  &__item {
    padding: 15px 15px;
    @include respond-to(sm) {
      padding: 15px 0;
    }
    @include respond-to(md) {
      padding: 25px 10px;
    }
    @include respond-to(lg) {
      padding: 25px 10px;
    }
    @include respond-to(xl) {
      padding: 35px 20px;
    }
  }
}
.flowers {
  &__title-img {

  }
  &__footer-img {
    margin: 50px auto 100px;
    position: relative;
    z-index: 10;
    @include respond-to(md) {
      margin: -150px auto 200px;
    }
    @include respond-to(lg) {
      margin: -200px auto 200px;
    }
  }
  &__content {
    background-image: none;
    padding: 30px 0;
    @include respond-to(md) {
      padding: 30px 140px 30px 0;
    }
    @include respond-to(lg) {
      padding: 50px 130px 130px;
    }
    @include respond-to(xl) {
      padding: 50px 130px 130px;
      background-image: url('/images/borders/border_1.png');
      background-repeat: no-repeat;
      background-position: left bottom;
      -webkit-background-size: contain;
      background-size: contain;
    }
  }
  &__text {

  }
  &__title {
    font-size: 40px;
    font-weight: normal;
    font-family: $subFont;
    line-height: 1.06;
    color: $text-color;
    text-transform: uppercase;
    padding-top: 40px;
    @include respond-to(sm) {
      font-size: 50px;
    }
    @include respond-to(md) {
      font-size: 60px;
    }
    @include respond-to(lg) {
      padding-top: 50px;
      font-size: 70px;
    }
  }
}
.presentation {
  margin-bottom: 100px;
  @include respond-to(md) {
    margin-bottom: 150px;
  }
  &__title {
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 30px;
    font-family: $subFont;
    text-transform: uppercase;
    @include respond-to(md) {
      font-size: 30px;
      font-weight: normal;
    }
    @include respond-to(lg) {
      font-size: 38px;
      font-weight: normal;
    }
  }
  &__text {
    margin-bottom: 30px;
  }
  &__img-wrapper {
    @include respond-to(xl) {
      background-repeat: no-repeat;
      background-position: left bottom;
      background-image: url('/images/borders/border_3.png');
      padding: 0 0 30px 120px;
    }
  }
  &__img {
    margin-bottom: 20px;
  }
  &__img-text {
    font-weight: normal;
    font-size: $fontSize;
    color: $dark-gray;
    font-family: $subFont;
  }
  .default-btn {
    margin: 20px 0;
  }
}
