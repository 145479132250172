.text-block {
  background-image: none;
  padding: 30px 0;
  font-size: 15px;
  font-weight: 300;

  @include respond-to(md) {
    padding: 30px 140px 30px 0;
  }
  @include respond-to(lg) {
    padding: 50px 130px 130px;
  }
  @include respond-to(xl) {
    padding: 50px 130px 130px;
    background-image: url('/images/borders/border_1.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    -webkit-background-size: contain;
    background-size: contain;
  }

  &__title {
    font-size: 35px;
    font-weight: normal;
    font-family: $subFont;
    color: $text-color;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  &__subtitle {
    font-size: 22px;
    font-weight: 300;
    font-style: italic;
    color: $text-color;
    font-family: $mainFont;
    margin-bottom: 20px;
  }
}

.advantages {


  &__title {
    margin-bottom: 100px;
    font-size: 40px;
    font-weight: normal;
    font-family: $subFont;
    color: $text-color;
    text-transform: uppercase;
  }
  &__item {
    background-image: url('/images/landing/borders/border_2.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    -webkit-background-size: contain;
    background-size: contain;
  }
  &__item-img {
    margin: 20px 40px;
  }
  &__item-title {
    font-size: 22px;
    font-weight: 300;
    color: $text-color;
    margin-bottom: 15px;
  }
  &__item-text {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 50px;
  }
}

.team {
  &__text-block {
    padding-top: 20px;
  }
  &__img-block {
    img {
      padding: 0 0 60px 50px;
      background-image: url('/images/landing/borders/border_3.png');
      background-repeat: no-repeat;
      background-position: left top;
    }
  }
  &__title {
    font-size: 42px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  &__text {
    font-size: 15px;
    font-weight: 300;
  }
}

.services {
  &__title {
    font-size: 35px;
    font-weight: normal;
    font-family: $subFont;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  &__subtitle {
    font-size: 22px;
    font-weight: 300;
    font-style: italic;
    font-family: $mainFont;
    margin-bottom: 100px;
  }
  &__item {

  }
  
}