//  стили созданные на основе стайлгайда

@font-face {
    font-family: 'rouble';
    src: url('/css/fonts/rouble-webfont.eot');
    src: url('/css/fonts/rouble-webfont.eot?#iefix') format('embedded-opentype'),
    url('/css/fonts/rouble-webfont.woff') format('woff'),
    url('/css/fonts/rouble-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

img {
    max-width: 100%;
}

h1, h2, h3, .h1, .h2, .h3 {
    margin-top: 0;
    margin-bottom: 0.5em;
    word-break: normal;
}

h1, .h1 {
    font-size: 30px;
    font-weight: normal;
    color: $text-color;
    font-family: $subFont;
    line-height: 1.2;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
    @include respond-to(sm) {
        font-size: 42px;
        margin-bottom: 40px;
    }
    @include respond-to(lg) {
        margin-bottom: 70px;
    }
}

h2, .h2 {
    font-size: 40px;
    font-weight: normal;
    color: $text-color;
    font-family: $subFont;
    line-height: 35px;
    text-transform: uppercase;
}

h3, .h3 {
    font-size: 24px;
    font-weight: bold;
    color: $text-color;
    font-family: $subFont;
    line-height: 30px;
}
a {
    color: $text-color;
    text-decoration: none;
    border: 0px solid black;

    &:visited {
        color: $black;
    }
    &:hover {
        color: $black;
        text-decoration: underline;
    }
    &:focus {
        color: $black;
        outline: none;
    }
    &:active {
        color: $black;
        outline: none;
    }
}


p {
    font-size: $fontSize;
    font-family: $mainFont;
    font-weight: 300;
    font-style: normal;
    line-height: 1.4;
    color: $text-color;
}


ul {
    list-style: none;
    padding-left: 0em;
    li {
        font-size: 14px;
        line-height: 1.79;
        letter-spacing: normal;
        color: $text-color;
        &:before {
            content: "—";
            color: $text-color;
            padding-right: 10px;
            display: inline-block;
        }
    }
}
ol {
    list-style: none;
    counter-reset: item;
    padding-left: 0em;
    li {
        font-size: 14px;
        position: relative;
        line-height: 2.07;
        &:before {
            content: counter(item) ". ";
            counter-increment: item;
            font-weight: bold;
            line-height: 1.71;
            color: $text-color;
        }
    }

}

.main-content {
    position: relative;
    background: rgba(255, 255, 255, 0.7);
    flex-grow: 1;
    padding-bottom: 100px;
}


//стили для полей ввода (инпуты, чекбоксы etc )

.group-item{
    input[type="radio"], input[type="checkbox"] {
        width: 100%;
        & + label {
            cursor: pointer;
            min-height: 25px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 300;

            &.doubleText {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .mainText {
                margin-bottom: 2px;
            }
            .subText {
                font-weight: 300;
            }
            a {
                font-size: 13px;
            }
        }
        &:not(checked) {
            position: absolute !important;
            opacity: 0;
        }
        &:not(checked) + label {
            position: relative;
            padding: 0 0 0 37px;
        }
        &:not(checked) + label:before {
            content: '';
            position: absolute;
            top: 2px;
            left: 0;
            width: 21px;
            height: 21px;
            border: 1px solid $dark-gray;
            margin-top: 0px;
        }
        &:not(checked) + label:after {
            content: '';
            position: absolute;
            top: -1px;
            left: 3px;
            width: 23px;
            height: 23px;
            transition: all .2s;
            display: none;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('/images/icons/checkboz-arrow.svg');

        }
        &:checked + label:after {
            display: block;
        }
    }
    textarea {
        border: solid 1px $dark-gray;
        font-size: 12px;
        font-weight: 300;
        color: $dark-gray;
        padding: 10px 15px;
        resize: none;
        width: 100%;
        max-width: 100%;
        height: 150px;
        background-color: transparent;
        outline: none;
        border-radius: 0;
        font-family: $subFont;
        &:hover {
            border-color: $pink;
        }
        &:active, &:focus {
            color: $dark-gray;
            box-shadow: none;
            border-color: $pink;
            outline: none;
        }
    }
    input[type="text"], input[type="password"], input[type="email"], input[type="phone"] {
        border: solid 1px $dark-gray;
        background: transparent;
        font-size: 16px;
        line-height: 51px;
        height: 51px;
        font-weight: 300;
        color: $dark-gray;
        padding: 0 15px;
        width: 100%;
        border-radius: 0;
        outline: none;
        font-family: $mainFont;

        &:hover {
            border-color: $pink;
        }
        &:active, &:focus {
            color: $dark-gray;
            box-shadow: none;
            border-color: $pink;
            outline: none;
            border-radius: 0;
        }

    }
    input[type="password"] {
        font-weight: 300;
        &:active, &:focus {
            font-weight: 300;
        }
    }
    label {
        .req {
            color: $dark-gray;
            font-size: $fontSize;
        }
    }

    &.failed-input {
        input {
            border-color: $pink;
        }
    }
    .failed-message {
        color: $pink;
        font-size: 11px;
        font-family: $mainFont;
        padding-top: 7px;
        display: block;
    }
}
.group-item{
    margin-bottom: 20px;
    position: relative;
}
/*
.selectpicker {
    .bootstrap-select {
        width: 300px;
        max-width: 100%;
    }
    &.small {
        .bootstrap-select {
            width: 220px!important;;
        }
    }
    &.medium {
        .bootstrap-select {
            width: 500px!important;;
        }
    }
    &.long {
        .bootstrap-select {
            width: 700px!important;
        }
    }
    .defaultSelect {
        background: white;
        text-transform: uppercase;
        border-radius: 0px;
        border: solid 1px $black;
        //background: transparent;

        font-size: 12px;
        line-height: 35px;
        font-weight: 300;
        color: $dark-gray;
        padding: 0 15px;
        &:focus {
            outline: none !important;
        }
        &.dropdown-toggle .caret {
            //background: url("/images/base_icon/bottom.svg") no-repeat;
            border: 0px;
            width: 23px;
            height: 19px;
            margin-top: 0;
            top: 10px;
        }

        &:hover {
            box-shadow: $defaultShadow;
        }
        &:active, &:focus, &[aria-expanded="true"] {
            box-shadow: $defaultShadow;
            border: solid 1px $black;
            outline: solid 1px $black;
            &.dropdown-toggle .caret {
                //background: url("/images/base_icon/up-blue.svg") no-repeat;
            }
        }
    }
    ul {
        li {
            &:before {
                display: none;
            }
            &.disabled{
                text-align: center;
                background: #ccc;
                .text{
                    color: $white;
                }
            }
        }
    }
    .dropdown-menu {
        padding: 0;
        margin: 0;
        box-shadow: 0px 0px 0px 0px black;
        box-shadow: 0 5px 7px 0 rgba(66, 57, 97, 0.11);
        border: solid 1px $black;
        outline: solid 1px $black;
        border-top: 0px;

        & > li > a {
            text-transform: uppercase;
            line-height: 35px;
            font-weight: 300;
            color: $black;
            padding: 0 15px;
            border-bottom: 1px solid $black;
            &:focus, &:hover, &:visited {
                font-style: normal;
                font-weight: 400;
                color: $black;
                background: none;
                outline: none;
            }
        }
    }
    .custom-select{
        border: 1px solid $dark-gray;
        border-radius: 0px;
    }
}
//стили для загрузки файла
.file-load {
    &__field {
        position: relative;
        overflow: hidden;
        border-bottom: 1px dashed $black;
        cursor: pointer;
        font-size: 12px;

        &:hover {
            border-bottom: 1px solid $black;
        }

        input[type=file] {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            font-size: 1em;
            text-align: right;
            filter: alpha(opacity=0);
            opacity: 0;
            outline: none;
            background: white;
            cursor: inherit;
            display: block;
        }
    }
    &__add-file {
        font-weight: bold;
        margin: 5px 0px;
    }
    &__rem-file {
        width: 1em;
        height: 1em;
        display: block;
        float: left;
        margin-right: 10px;
        cursor: pointer;
    }
}
*/
// стили для кнопок

.default-btn {
    display: inline-block;
    text-align: center;
    font-size: 20px;
    font-family: $subFont;
    line-height: 24px;
    color: $charcoal;
    background-color: $white;
    padding: 12px 30px 9px;
    margin: 20px 0;
    cursor: pointer;
    border: 3px solid $charcoal;
    text-decoration: none;
    outline: none;
    box-shadow: none;
    text-transform: uppercase;
    &:hover {
        border-color: $pink;
        color: $pink;
        text-decoration: none;
    }
    &:active {
        outline: none;
        box-shadow: none;
        border-color: $pink;
        color: $pink;
    }
    &:focus {
        outline: none;
        box-shadow: none;
        border-color: $pink;
        color: $pink;
    }
    &:visited {
        outline: none;
        box-shadow: none;
        border-color: $charcoal;
        color: $charcoal;
    }
    &:disabled {
        outline: none;
        box-shadow: none;
        border-color: $light-gray;
        color: $light-gray;
    }
    &__wrapper {
        display: block;
        text-align: center;
        &--right {
            text-align: right;
        }
    }
}

blockquote {
    font-style: italic;
    margin: 0 0 0;
    font-size: $fontSize;
    font-style: italic;
    color: $text-color;
    vertical-align: middle;
    font-weight: 300;
    padding-left: 45px;
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url('/images/icons/quote.svg');
    }
}

hr {
    width: 100%;
    height: 1px;
    background: $black;
    border: 0;
}

html{
    height: 100%;
}
body {
    color: $text-color;
    font-family: $mainFont;
    font-weight: 300;
    overflow-x: hidden;
    line-height: 1.4;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    &.noscroll {
        overflow: hidden;
    }
}