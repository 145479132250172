.order-form {
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    @include respond-to(md) {
      justify-content: space-between;
      width: 80%;
      margin: 0 auto;
    }
  }
  &__message {
    width: 80%;
    min-width: 80%;
    margin: 0 auto 20px;
  }
  &__submit {
    width: 100%;
    min-width: 100%;
    order: 4;
    @include respond-to(md) {
      width: 15%;
      min-width: 15%;
      order: 3;
    }
    .default-btn {
      margin: 0 0 20px 0;
      padding: 12px 30px 9px;
    }
  }
  .group-item {
    width: 100%;
    min-width: 100%;
    @include respond-to(md) {
      width: 40%;
      min-width: 40%;
      padding-right: 40px;
    }
    input {
      color: $pink;
      @include placeholder {
        font-size: 15px;
        color: $dark-gray;
        font-weight: 300;
        font-family: $subFont;
      }
      &:focus {
        @include placeholder {
          color: $white !important;
        }
      }
    }
  }
  .policy-group-item {
    min-width: 100%;
    order: 3;
    @include respond-to(md) {
      width: 100%;
      min-width: 100%;
      order: 4;
    }
  }
}